import React, { Component } from "react";
import {withRouter} from 'react-router-dom';
import Main from './Main'


class App extends Component {
  
  render() {
    return (
      <div>
        <Main />
      </div>
    );
  }
}   

export default withRouter(props => <App {...props}/>);