import React, { Suspense, lazy } from 'react';
import { Switch, Route } from 'react-router-dom';

// import Home from './pages/Home';
// import HomeResultados from './pages/HomeResultados';
// import Room from './pages/Room';

// import Utils from './pages/Utils';
// import UtilsNomina from './pages/UtilsNomina';

// import ImportResult from './pages/ImportResult';

// import ListadoAlumnos from './pages/ListadoAlumnos';
// import ListadoResultados from './pages/ListadoResultados';

const Home = lazy(() => import('./pages/Home'));
const HomeResultados = lazy(() => import('./pages/HomeResultados'));
const Room = lazy(() => import('./pages/Room'));

const Utils = lazy(() => import('./pages/Utils'));
const UtilsNomina = lazy(() => import('./pages/UtilsNomina'));

const ImportResult = lazy(() => import('./pages/ImportResult'));

const ListadoAlumnos = lazy(() => import('./pages/ListadoAlumnos'));
const ListadoResultados = lazy(() => import('./pages/ListadoResultados'));

const Main = () => (
  <main className='main'>
    <Suspense fallback={<div>Loading...</div>}>
      <Switch>
        <Route exact path='/' component={Home} />
        <Route exact path='/resultados' component={HomeResultados} />
        <Route exact path='/room/:id' component={Room} />

        <Route exact path='/utils' component={Utils} />
        <Route exact path='/utils/importalumnos' component={UtilsNomina} />
        <Route exact path='/utils/importresults' component={ImportResult} />

        <Route exact path='/utils/alumnos' component={ListadoAlumnos} />
        <Route exact path='/utils/resultados' component={ListadoResultados} />

        {/* <Route exact path='/logout' component={ (props) => {
                localStorage.removeItem('beuser')
                props.history.push('/');
                return <Registro />;
            }} /> */}
      </Switch>
    </Suspense>
  </main>
);

export default Main;
